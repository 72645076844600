import React from 'react';
import './inputfield.scss';
import {ReactComponent as InfoIcon} from '../../assets/images/circle-exclamation-solid.svg';
const InputField = ({
  disabled = false,
  type = "text",
  value = undefined,
  name,
  label,
  onChange,
  error = false,
  errorText = "",
}) => {
  return (
    <div className="input-box-container">
      <div className={`input-box${error?' error':''}`}>
        <p className="input-label">{label}</p>
        <input
          type={type}
          value={value}
          onChange={(e)=>onChange(name, e.target.value)}
          name={name}
          disabled={disabled}
        />
      </div>
      {error && (
        <div className="input-error-container">
          <InfoIcon/>
          {errorText}
        </div>
      )}
    </div>
  );
};

export default InputField;
