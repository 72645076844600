import SociaIcons from "../../components/SocialIcons";
import PageTitle from "./PageTitle";
import "./watch.scss";
import useQuery from "../../Utilities/useQuery";
import React from "react";
import Banner from "../../components/CollectionBanner/index.js"

const Watch = () => {
  let query = useQuery();
  const [url, setUrl] = React.useState();

  React.useEffect(() => {
    const temp = query.get("stream");
    temp && temp.length>0 && setUrl(decodeURIComponent(temp));
  }, [query]);
  return (
    <section id="watch-page">
      <div className="container">
        <PageTitle />
        {url && (
          <>
          <div className="video-container">
            <div className="video-frame">
              <iframe 
                id="iframe"
                src={url}
                title="live-event"
                className="player"
                width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
              ></iframe>
            </div>
           
            </div>
            <SociaIcons variant={"light"} />
          </>
        )}
      </div>
        <Banner page={"watch"} />
    </section>
  );
};

export default Watch;
