import React, { useState } from "react";
import Logo from "../../components/Logo";
import SociaIcons from "../../components/SocialIcons";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
import "./comingsoon.scss";
import IntroText from "./IntroText";
import BottomLogo from "../../components/BottomLogo";
import RegisterSuccess from "./RegisterSuccess";
import RegisterForm from "../RegisterForm";

const ComingSoon = () => {
  const { width } = useWindowDimensions();  
  const [registerSuccess, setRegisterSuccess] = useState(false);

  return (
    <section id="coming-soon-page">
      <div className="container">
        <div className="video-container">
          <iframe className="video-frame" src={width <= 999 ? 'https://player.vimeo.com/video/1032397335?badge=0&muted=1&loop=1&autoplay=1&autopause=0&controls=0' : 'https://player.vimeo.com/video/1032397132?badge=0&muted=1&loop=1&autoplay=1&autopause=0&controls=0'} width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        {width < 1000 && (
          <>
          <div className="cabiLogoContainer">
            <Logo variant={width <= 999 ? "light" : "light"} />
          </div>
            <br />
            <br />
          </>
        )}
        <div className="page-content">
            <IntroText success={registerSuccess}/>
          {!registerSuccess ? (<>
            <RegisterForm onSuccess={() => setRegisterSuccess(true)} parent={"coming"}/>
            </>
          ) : (
            <RegisterSuccess />
          )}
        </div>
        <br />
        <div>
        {/* <SociaIcons variant={width <= 999 ? "dark" : "light"}/>
        {width>=1000 && <BottomLogo variant={width <= 999 ? "dark" : "light"}/>} */}
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
