import Typography from "../../components/Typography";

const RegisterSuccess = () => {
  return (
    <div className="register-success">
        <Typography variant="h4">
          <strong>Thank you for registering. </strong>Keep your eye on your inbox as we get close to the show for your special access link.
        </Typography>
    </div>
  );
};

export default RegisterSuccess;
