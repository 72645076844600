import React from "react";
import "./button.scss";

const Button = ({
  onClick,
  disabled = false,
  type = undefined,
  loading = false,
  children,
  variant="dark"
}) => {
  return (
    <button
      className={`button ${variant}`}
      onClick={onClick}
      tabIndex={0}
      disabled={disabled || loading}
      type={type}
      onKeyPress={(e) => e.key === "Enter" && onClick && onClick()}
    >
      {loading ? '......' : children}
    </button>
  );
};

export default Button;