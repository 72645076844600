import { useState } from "react";
import SociaIcons from "../../components/SocialIcons";
import PageTitle from "./PageTitle";
import VideoTab from "./VideoTab";
import "./virtualscoop.scss";
import Banner from "../../components/CollectionBanner/"

const tabs = [
  {
    title: "cabi Fashion Show Highlights",
    description: "Get a glimpse of our Fall 2023 Collection on the runway.",
    vimeoId: "851717890" ,
  },
  {
    title: "Runway to Runway",
    description:
      "Gather with the cabi Design Team for a special design inspiration session. Watch as they delve into the key trends that inspired the iconic Collection.",
    vimeoId: "851691918",
  },
  {
    title: "Creative Insights",
    description:
      "Join Daniele Trussardi for exclusive details behind the timeless, sophisticated, and charming Collection inspired by the icons we look up to.",
    vimeoId: "851691126",
  }
];
const VirtualScoop = () => {
  const [active, setActive] = useState(0);

  return (
    <section id="virtualscoop">
      <div className="container">
        <PageTitle />
        <div className="watch-container">
          <div className="video">
            <iframe
              src={`https://player.vimeo.com/video/${tabs[active].vimeoId}`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              title="cabi fashion show"
              allowFullScreen
            ></iframe>
          </div>

          <div className="video-items">
            {tabs.map((tab, index) => (
              <VideoTab
                title={tab.title}
                description={tab.description}
                vimeoId={tab.vimeoId}
                key={index}
                active={active === index}
                handleActive={() => setActive(index)}
              />
            ))}
          </div>
        </div>
        <SociaIcons variant="light" />
      </div>
      <Banner page={"scoop"}/>
    </section>
  );
};

export default VirtualScoop;
