import React from 'react';
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/x-twitter.svg";
import { ReactComponent as PinterestIcon } from "../../assets/images/pinterest.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";
import "./socialicons.scss";

const SociaIcons = ({variant="dark"}) => {
  return (
    <div className="socials-container">
      <div className={`socials ${variant}`}>
        <div className="social">
          <a
            href="http://www.facebook.com/cabiclothing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </div>
        <div className="social">
          <a
            href="http://www.instagram.com/cabiclothing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
        <div className="social">
          <a
            href="http://twitter.com/cabiclothing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="social">
          <a
            href="http://pinterest.com/cabiclothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PinterestIcon />
          </a>
        </div>
        <div className="social">
          <a
            href="http://youtube.com/cabiclothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon />
          </a>
        </div>
      </div>
    </div>
  );
};
export default SociaIcons;
