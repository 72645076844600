import React from "react";
import Typography from "../../components/Typography";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
// import titleLogo from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy.svg"
// import titleLogoMobile from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy-mobile.svg"
import comingSoonDesktop from '../../assets/images/s25/coming-soon.svg'
import comingSoonMobile from '../../assets/images/s25/coming-soon-mobile.svg'
import lockup from '../../assets/images/s25/cabi-spring-2025.svg'

const IntroText = ({success}) => {
  const { width } = useWindowDimensions();
  // let isMobile = false
  // if(width<480){
  //   isMobile = true
  // } 
  return (
    <>

      <img src={width <= 999 ? comingSoonMobile : comingSoonDesktop} className="coming-soon" alt="Coming Soon"></img>
      {/* <Typography variant="h1">
        COMING SOON
      </Typography> */}
      <h2>
        <img src={lockup} className="coming-soon" alt="Coming Soon"></img>
        {/* <span>cabi’s</span> spring 2025<br></br>Fashion Show */}
      </h2>
      {/* <br /> */}
      <Typography variant="h3">
        <strong>JANUARY 16, 2025</strong>
        <br />
        2:00 P.M PST | 5:00 P.M. EST | 10:00 P.M. U.K. (UTC)
      </Typography>
      <div className="contain-tablet-down">
        {success? (<> </>) :
          (
            <>   
            <Typography variant="h4">
            <span></span>
            </Typography>
            </>
          )
        }
        {!success && <Typography variant="h4">
          By registering, you are opting in to receive exclusive emails from cabi.{" "}
          {/* <a href="https://www.cabionline.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> */}
        </Typography>}
      </div>
    </>
  );
};

export default IntroText;
