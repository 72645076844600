import React from 'react'
import vslockup from '../../assets/images/f23/explore-the-fall-collection-lockup.svg'
import lockup from '../../assets/images/f24/shop-fall-2024-lockup.svg'
import "./collectionBanner.scss"

const Banner= ({page}) =>{

    return (
        <>
        <div id="banner">
            <div className="banner-text-container">
                {page == 'scoop' &&
                    <img src={vslockup} className="banner-lockup" alt="Explore the Fall 2024 Collection"></img>
                }
                {page == 'watch' &&
                    <img src={lockup} className="banner-lockup" alt="Shop the Fall 2024 Collection"></img>
                }
                <a className="banner-button" href="https://www.cabionline.com/collection/clothes/" target="_blank" rel="noreferrer">LET'S SHOP</a>
            </div>
        </div>
        </>
    )
}

export default Banner