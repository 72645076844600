import Logo from "../../components/Logo";
import SociaIcons from "../../components/SocialIcons";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
import IntroText from "./IntroText";
import './dayofshow.scss';
import BottomLogo from "../../components/BottomLogo";
import RegisterForm from "../RegisterForm";
import { useNavigate } from "react-router-dom";
import Banner from '../../components/CollectionBanner';

const DayOfShow = () => {
  const { width } = useWindowDimensions();
  let isDesktop = false
  if(width>768){isDesktop = true} 
  const navigate = useNavigate();

  const handleSuccess=(route)=> {     
      navigate(`/watch?stream=${encodeURIComponent(route)}`, { replace: true });
  }
  
  return <section id="dayofshow-page">
    <div className="container">
      <div className="page-content">
        <IntroText />
        <RegisterForm onSuccess={handleSuccess} validateByTime={true} parent={"DayOfShow"}/> 
      </div>
      <div>
          {width < 640 && (
            <div className="mobile-bottom-logo">
              <Logo variant="dark" />
              <br />
              <br />
            </div>
          )}
          <SociaIcons variant="dark"/>
          {width>=640 && <BottomLogo variant="dark"/>}
        </div>
    </div>
    {/* <Banner /> */}
  </section>;
};

export default DayOfShow;
