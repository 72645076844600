const VideoTab = ({ title, description, thumbnail, handleActive, active }) => {
  return (
    <div
      className={`item${active ? " active" : ""}`}
      tabIndex={0}
      onKeyDown={(e) => e.keyCode === 13 && handleActive()}
      onClick={() => handleActive()}
    >
      <p className="title">{title}</p>
      {active && <p className="description">{description}</p>}
    </div>
  );
};

export default VideoTab;
