import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/images/cabilogo-white.svg";
import "./logo.scss";

const Logo = ({ variant = "dark" }) => {
  return (
    <a
      href="http://www.cabionline.com"
      target="_blank"
      rel="noopener noreferrer"
      className={`logo ${variant}`}
    >
      <LogoIcon/>
    </a>
  );
};

export default Logo;
