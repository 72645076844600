import './modal.scss';
import {ReactComponent as CloseIcon} from '../../assets/images/xmark-solid.svg';
const Modal=({isOpen, close, children})=> {
    return <>
    {isOpen && (
        <div className="modal-container" onClick={close}>
            <div className={`modal-wrapper`} onClick={(e) => e.stopPropagation()}>
                <div className='header'>
                    <div className="modal-close">
                        <CloseIcon onClick={close}/>
                    </div>
                </div>
                <div className="modal-content">{children}</div>
            </div>
        </div>
    )}
</>
}

export default Modal;