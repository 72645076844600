import axios from "axios";

const instance = axios.create({
  baseURL: '/',
});

const postUserData = (user) => {
  return instance.post(`postUserData.php`, user).then((response) => {
    return response.data;
  });
};

const validateUserData = (user) => {
  return instance.post(`validateUserData.php`, user).then((response) => {
    return response.data;
  });
};

const getCaptcha = () => {
  const payload = {
    "id": 1,
    "jsonrpc": "2.0",
    "method": "GetCaptcha",
    "params": {}
  };
  return instance.post(`replicated/control/jsonrpc`, payload).then((response) => {
    return response.data;
  });
};

const verifyCaptcha =(captchaResponse)=> {
  const payload = {
    "id": 1,
    "jsonrpc": "2.0",
    "method": "CheckCaptcha",
    "params": {'captchaAnswer':captchaResponse}
  };
  return instance.post(`replicated/control/jsonrpc`, payload).then((response) => {
    return response.data;
  });
}

export { postUserData, getCaptcha, verifyCaptcha, validateUserData };
