import Logo from '../../components/Logo';
import title from '../../assets/images/s23/s23-fsb-watch-lockup.svg'
import titleMobile from '../../assets/images/s23/s23-fsb-watch-lockup-mobile.svg'
import useWindowDimensions from "../../Utilities/useWindowDimensions";
const PageTitle=()=> {
    const { width } = useWindowDimensions();  
    let isMobile = false
    if(width<768){isMobile = true} 
    return <div className="page-title">
        <div className='title-text-container'>
            <span>cabi’s fall 2024</span><br></br>Fashion Show
        </div>
        <Logo variant='light'/>
    </div>
}

export default PageTitle