import React from 'react'; 
import './typography.scss';

const Typography=({variant="P", children})=> {
    switch(variant)
    {
        case "h1":
            return <h1 className='typography-h1'>{children}</h1>;
            case "h2":
            return <h2 className='typography-h2'>{children}</h2>;
            case "h3":
            return <h3 className='typography-h3'>{children}</h3>;
            case "h4":
            return <h4 className='typography-h4'>{children}</h4>;
            case "p":
            return <p className='typography-p'>{children}</p>;
            default:
            return <p className='typography-p'>{children}</p>;
    }
}

export default Typography;