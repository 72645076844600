import "./bottomlogo.scss";
import { ReactComponent as LogoIcon } from "../../assets/images/cabilogo-white.svg";

const BottomLogo = ({ variant = "dark" }) => {
  return <a
    href="http://www.cabionline.com"
    target="_blank"
    rel="noopener noreferrer"
    className={`bottom-logo ${variant}`}
  >
    <LogoIcon />
  </a>;
};

export default BottomLogo;
